<template lang="">
    <div>
      <Card>
        <template #header> Belgeler </template>
        <div class="row">
          <div class="col-md-3 col-12">
            <div class="form-group">
              <label for="">Belge Adı</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-3 col-12">
            <div class="form-group">
              <label for="">Belge Açıklaması</label>
              <textarea
                name=""
                id="aciklama"
                rows="1"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <b-form-group class="mb-0">
              <label for="file"> Belge yüklemek için dosya seç </label>
              <b-form-file
                id="file"
                class="rounded-sm"
                placeholder="Dosya seçiniz"
                drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
                accept="image/*"
                multiple
              >
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                  <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                    + {{ names.length - 1 }} More files
                  </b-badge>
                </template>
              </b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-6 col-12 d-flex align-items-end">
            <b-button variant="light">
              <i class="flaticon2-search-1"></i>
              Seçilen dosyaları ekle
            </b-button>
          </div>
        </div>
        <b-alert show variant="secondary" class="mt-4"
          >PDF, PNG, JPG formatlarında yükleme yapabilirsiniz.</b-alert
        >
        <div class="row">
          <div class="col-md-4 col-12">
            <b-card img-top tag="article" class="dveb-gray-card" no-body>
              <b-card-img
                src="/image/ilan1.png"
                alt="Image"
                class=""
                @click="imageEditModal = true"
              ></b-card-img>
  
              <div class="row p-4">
                <div class="form-group w-100">
                  <label for="">Belge Açıklaması</label>
                  <input type="text" class="form-control" />
                </div>
                <div class="form-group w-100">
                  <label for="">İçerik</label>
                  <select name="" id="" class="form-control">
                    <option value="" selected>Seçiniz</option>
                    <option value="ruhsat">Ruhsat</option>
                    <option value="tapu">Tapu</option>
                    <option value="sozlesme">Kira Sözleşmesi</option>
                  </select>
                </div>
                <b-button variant="light" class="w-100">
                  <i class="flaticon2-trash text-danger"></i>
                  Sil
                </b-button>
                <b-button variant="light" class="w-100 mt-3">
                    <i class="flaticon2-pen text-primary"></i>
                 Düzenle
                </b-button>

              </div>
            </b-card>
          </div>
        </div>
      </Card>
  
      <b-modal
        v-model="imageEditModal"
        size="2xl"
        id="note-modal"
        @shown="initCropper"
        @hidden="destroyCropper"
      >
        <template #modal-header>
          <h4>Resmi Düzenle</h4>
        </template>
        <div class="row">
          <div class="col-9" ref="cropperContainer">
            <img :src="imageSrc" class="img-fluid" alt="" />
          </div>
          <div class="col-3">
            <div class="row" v-if="croppedDataUrl">
              <img
                :src="croppedDataUrl"
                class="img-fluid"
                ref="croppedImg"
                alt=""
              />
            </div>
            <div class="row" style="gap: 5px">
              <button class="btn btn-primary col mt-2" @click="zoomIn">
                Yakınlaştır
              </button>
              <button class="btn btn-primary col mt-2" @click="zoomOut">
                Uzaklaştır
              </button>
              <button class="btn btn-primary col mt-2" @click="rotateLeft">
                Sola Döndür
              </button>
              <button class="btn btn-primary col mt-2" @click="rotateRight">
                Sağa Döndür
              </button>
              <button class="btn btn-primary col mt-2" @click="flipHorizontal">
                Yatay Çevir
              </button>
              <button class="btn btn-primary col mt-2" @click="flipVertical">
                Dikey Çevir
              </button>
              <button class="btn btn-primary col mt-2" @click="croppedImg">
                Kırp
              </button>
  
              <button class="btn btn-primary col mt-2" @click="reset">
                Sıfırla
              </button>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="hideModal"> Vazgeç </b-button>
            <b-button variant="success" class="ml-4"> Kaydet </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import 'cropperjs/dist/cropper.css';
  
  import Cropper from "cropperjs";
  
  export default {
    data() {
      return {
        imageEditModal: false,
        cropper: {},
        croppedDataUrl: "",
        imageSrc: "/image/ilan1.png",
      };
    },
  
    watch: {
      imageEditModal(newValue) {
        if (newValue) {
          this.initCropper();
        }
      },
    },
    methods: {
      initCropper() {
        const cropperContainer = this.$refs.cropperContainer;
        const imageElement = cropperContainer?.querySelector("img");
        // veya gerekli elementi bulmak için uygun seçiciyi kullanın
        if (imageElement) {
          const cropper = new Cropper(imageElement, {
            // Cropper.js yapılandırma seçeneklerini burada ayarlayabilirsiniz
          });
          this.imageEditModal = true;
  
          this.cropper = cropper;
        }
      },
      destroyCropper() {
        const cropperContainer = this.$refs.cropperContainer;
        const imageElement = cropperContainer?.querySelector("img");
        if (imageElement && imageElement.cropper) {
          imageElement.cropper.destroy();
        }
      },
      hideModal() {
        this.imageEditModal = false;
        $bvModal.hide("note-modal");
      },
      zoomIn() {
        this.cropper.zoom(0.1);
      },
      zoomOut() {
        this.cropper.zoom(-0.1);
      },
      rotateLeft() {
        this.cropper.rotate(-90);
      },
  
      rotateRight() {
        this.cropper.rotate(90);
      },
      flipHorizontal() {
        if (this.cropper) {
          this.cropper.scaleX(-this.cropper.imageData.scaleX || -1);
        }
      },
      flipVertical() {
        if (this.cropper) {
          this.cropper.scaleY(-this.cropper.imageData.scaleY || -1);
        }
      },
      croppedImg() {
        if (this.cropper) {
          const croppedCanvas = this.cropper.getCroppedCanvas();
          if (croppedCanvas) {
            croppedCanvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                this.croppedDataUrl = reader.result;
              };
            });
          }
          this.cropper.destroy();
          this.cropper = null;
          this.initCropper();
        }
      },
  
      reset() {
        this.cropper.reset();
        this.croppedDataUrl = "";
        this.imageSrc = "/image/ilan1.png"; // Sıfırlama işleminden sonra ana resmi geri yükle
      },
    },
  };
  </script>
  